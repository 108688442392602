<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import SchoolsDelete from "@/modules/schools/components/schools-delete.vue";
import SchoolsRemove from "@/modules/schools/components/schools-remove.vue";
import SchoolsShow from "@/modules/schools/components/schools-show.vue";
import inputsSchools from "@/modules/schools/inputsSchools";
import SchoolsInfosStore from "@/modules/schools-infos/schools-infos-store";
import SchoolInput from "@/components/SchoolInput.vue";
import PeoplesStore from "@/modules/peoples/peoples-store";
import PeoplesTypesStore from "@/modules/peoples-types/peoples-types-store";
import PeoplesRacesStore from "@/modules/peoples-races/peoples-races-store";
import SchoolsTypesStore from "@/modules/schools-types/schools-types-store";

export default {
  name: "Escolas",
  data() {
    return {
      title: "Escolas",
      single: "Escola",
      items: [
        {text: "Home", to: {name: "home"}},
        {text: "Escolas", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "Identificador", key: "iduuid"},
        {label: "Criação", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      fields: inputsSchools,
      buscar: "",
      form: {
        id: null,
        name: null,
        iduuid: null,
        is_plataform: null,
        is_genesis: null,
        id_people: null,
        firebase_domain: null,
        firebase_domain_temp: null,
        firebase_storage: null,
        firebase_auth_tenant_id: null,
        firebase_project_id: null,
        firebase_measurement_id: null,
      },
      optionsPeopleTypes: [{text: "Selecione", value: null}],
      optionsPeopleRaces: [{text: "Selecione", value: null}],
      optionsSex: [
        {text: "Masculino", value: "M"},
        {text: "Feminino", value: "F"},
        {text: "Juridica", value: "PJ"},
      ],
      optionsYesNo: [
        {value: 1, text: 'Sim'},
        {value: 0, text: 'Não'},
      ],
      optionsType:[{text: "Selecione", value: null}],
      formPeople: {
        id: null,
        id_people_type: null,
        id_people_race: null,
        sex: null,
        name: null,
        document: null,
        photo: null,
        date_of_birth: null,
      },
    }
  },
  components: {SchoolInput, SchoolsShow, SchoolsRemove, SchoolsDelete, SchoolsPaginate, Layout, PageHeader},
  mounted() {

    this.getPaginate(this.paginate.page);
  },
  watch: {
    'form.name': function (newValue) {
      // this.form.identifier = this.$slug_format(newValue);
    }
  }, methods: {
    async updateConfig(value, file = false) {

      const item = this.fields[value.key];
      value.form.id_schools = this.form.id;
      value.form.key = value.key;

      if (file) {
        value.form.value = file;
      } else {
        value.form.value = value.form.value.toString();
      }

      console.log('updateConfig', value, item, file);

      item.isLoad = true;
      item.request = true;
      let response = null;

      if (value.form.id) {
        response = await SchoolsInfosStore.update({
          id: value.form.id,
          key: value.form.key,
          value: value.form.value,
        });
      } else {
        response = await SchoolsInfosStore.create(value.form);
      }
      item.isLoad = false;
      item.request = null;
      if (response.success) {
        this.fields[value.key].form = response.data;
      } else {
        Swal.fire({
          title: 'Erro',
          text: `Erro ao salvar o campo ${item.label}`,
          icon: 'error',
          confirmButtonText: 'OK',
          toast: true,
          timer: 2500,
          timerProgressBar: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }

    },
    async getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;

      await this.loadOptionsPeoplesTypes();
      await this.loadOptionsPeoplesRaces();
      await this.loadOptionsType();

      SchoolsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        name: null,
        iduuid: null,
        is_plataform: null,
        id_type: null,
        is_genesis: null,
        id_people: null,
        firebase_domain: null,
        firebase_domain_temp: null,
        firebase_storage: null,
        firebase_auth_tenant_id: null,
        firebase_project_id: null,
        firebase_measurement_id: null,
      }
    },
    reloadForm(){
      this.isLoadShow = true;
      SchoolsStore.get(this.form.id).then((response) => {
        this.form = response.data;
        this.form.schools_peoples.forEach((item) => {
          item.peoples.date_of_birth = item.peoples.date_of_birth.split('T')[0];
        });
      }).finally(() => {
        this.isLoadShow = false;
      });
    },
    edit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      SchoolsInfosStore.all({id_schools: this.form.id}).then((response) => {
        response.data.data.forEach((item) => {
          console.log('item', item);
          if (this.fields[item.key]) {
            this.fields[item.key].form = item;
          }
        });
      });

      try {
        this.form.schools_peoples.forEach((item) => {
          item.peoples.date_of_birth = item.peoples.date_of_birth.split('T')[0];
        });
      } catch (e) {
        console.log(e);
      }

      this.showNewForm = true;
    },
    savePeople(e, people) {
      e.preventDefault();
      console.log('people', people);

      const formData = new FormData();
      if (people.id) {
        formData.append("id", people.id);
      }

      formData.append("id_school", this.form.id);
      formData.append("id_people_type", people.id_people_type);
      formData.append("id_people_race", people.id_people_race);
      formData.append("sex", people.sex);
      formData.append("name", people.name);
      formData.append("document", people.document);
      formData.append("date_of_birth", people.date_of_birth);

      if (people.photo) {
        formData.append("photo", people.photo);
      }
      if (people.id) {
        PeoplesStore.update(people.id, formData).then((response) => {
          people = response.data;

          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Cadastro atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        PeoplesStore.create(formData).then((response) => {
          people = response.data;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Cadastro criado com sucesso",
          });
          this.formPeople = {
            id: null,
            id_people_type: null,
            id_people_race: null,
            sex: null,
            name: null,
            document: null,
            photo: null,
            date_of_birth: null,
          };
          this.reloadForm();
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    },
    handlerUpload(event) {
      this.form.photo = event.target.files[0];
      console.log(this.form.photo);
    },
    async loadOptionsType(){
      await SchoolsTypesStore.all().then((response) => {
        this.optionsType.push(...response.data.map((item) => {
          return {text: item.name, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoplesTypes() {
      await PeoplesTypesStore.all().then((response) => {
        this.optionsPeopleTypes.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoplesRaces() {
      await PeoplesRacesStore.all().then((response) => {
        this.optionsPeopleRaces.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        SchoolsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Escola atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        SchoolsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Escola criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    },
    removePeopleSchool(people) {
      Swal.fire({
        title: 'Deseja realmente remover?',
        text: "Você poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, remover!'
      }).then((result) => {
        if (result.isConfirmed) {
          PeoplesStore.deletePeopleSchool(people.id).then((response) => {
            this.reloadForm();
            Swal.fire({
              icon: "success",
              title: "Removido",
              text: "Pessoa removida com sucesso",
            });
          }).catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Erro ao remover",
              text: "Erro ao remover a pessoa",
            });
          });
        }
      });
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">


                            <b-tabs content-class="mt-3">
                              <b-tab title="Principal" active>
                                <b-form @submit="save" class="was-validated">

                                  <b-form-group label="Tipo de escola" label-for="input-3" description="tipo">
                                    <b-form-select id="input-3" v-model="form.id_type" :options="optionsType"></b-form-select>
                                  </b-form-group>
                                  <b-form-group label="Nome da escola" label-for="input-1" description="Nome da escola completo">
                                    <b-form-input id="input-1" v-model="form.name" required></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Identificador unico da escola" label-for="input-2" description="Identificador da escola">
                                    <b-form-input id="input-2" v-model="form.iduuid" required></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Tem plataforma?" label-for="input-3" description="É plataforma">
                                    <b-form-select id="input-3" v-model="form.is_plataform" :options="optionsYesNo"></b-form-select>
                                  </b-form-group>
                                  <b-form-group label="Tem Genesis?" label-for="input-4" description="É genesis">
                                    <b-form-select id="input-4" v-model="form.is_genesis" :options="optionsYesNo"></b-form-select>
                                  </b-form-group>
                                  <b-button type="submit" variant="primary">
                                    <span v-if="form.id">Salvar alterações</span>
                                    <span v-if="!form.id">Cadastrar novo</span>
                                  </b-button>
                                </b-form>
                              </b-tab>
                              <b-tab title="Informações extras" v-if="form.id" >
                                <div class="row" v-if="form.id">
                                  <template v-for="item in fields">
                                    <div v-if="item.breakLine" class="col-12" :key="item.key">
                                      <br><br>
                                      <h3>{{ item.titleSession }}</h3><br>
                                    </div>
                                    <div class="col-6 " :key="item.key">
                                      <SchoolInput :config="item" @changeUpdate="updateConfig"/>
                                    </div>


                                  </template>
                                </div>
                              </b-tab>
                              <b-tab title="Configurações" v-if="form.id">
                                <b-form @submit="save" class="was-validated">
                                  <b-form-group label="Domínio do firebase" label-for="input-3" description="Domínio do firebase">
                                    <b-form-input id="input-3" v-model="form.firebase_domain"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Domínio temporário do firebase" label-for="input-4" description="firebase_domain_temp">
                                    <b-form-input id="input-4" v-model="form.firebase_domain_temp"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Armazenamento do firebase" label-for="input-5" description="firebase_storage">
                                    <b-form-input id="input-5" v-model="form.firebase_storage"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="ID do inquilino de autenticação do firebase" label-for="input-6" description="firebase_auth_tenant_id">
                                    <b-form-input id="input-6" v-model="form.firebase_auth_tenant_id"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="ID do projeto do firebase" label-for="input-7" description="firebase_project_id">
                                    <b-form-input id="input-7" v-model="form.firebase_project_id"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="ID da medição do firebase" label-for="input-8" description="firebase_measurement_id">
                                    <b-form-input id="input-8" v-model="form.firebase_measurement_id"></b-form-input>
                                  </b-form-group>
                                  <b-button type="submit" variant="primary">
                                    <span v-if="form.id">Salvar alterações</span>
                                    <span v-if="!form.id">Cadastrar novo</span>
                                  </b-button>
                                </b-form>
                              </b-tab>
                              <b-tab title="Sócios/Administradores" v-if="form.id">


                                <h4>Novo</h4>
                                <b-form @submit="savePeople($event,formPeople)" class="was-validated row">

                                  <!--                              id_people_type-->
                                  <b-form-group class="col-sm-4" label="Tipo de pessoa" description="Selecione o tipo de pessoa">
                                    <b-form-select
                                        id="id_people_type"
                                        v-model="formPeople.id_people_type"
                                        :options="optionsPeopleTypes"
                                        required
                                    ></b-form-select>
                                  </b-form-group>
                                  <!--                              id_people_race-->
                                  <b-form-group class="col-sm-4" label="Raça pessoa" description="Selecione raça">
                                    <b-form-select
                                        id="id_people_race"
                                        v-model="formPeople.id_people_race"
                                        :options="optionsPeopleRaces"
                                        required
                                    ></b-form-select>
                                  </b-form-group>
                                  <!--                              sex-->
                                  <b-form-group class="col-sm-4" label="Sexo" description="Selecione o sexo">
                                    <b-form-select
                                        v-model="formPeople.sex"
                                        :options="optionsSex"
                                        required
                                    ></b-form-select>
                                  </b-form-group>
                                  <!--                              name-->
                                  <b-form-group class="col-sm-6" label="Nome" label-for="name" description="Nome da pessoa">
                                    <b-form-input id="name" v-model="formPeople.name" required></b-form-input>
                                  </b-form-group>
                                  <!--                              document-->
                                  <b-form-group class="col-sm-6" label="Documento" label-for="document" description="Documento da pessoa">
                                    <b-form-input id="document" v-model="formPeople.document" required></b-form-input>
                                  </b-form-group>
                                  <!--                              photo-->
                                  <b-form-group class="col-sm-4" label="Foto" label-for="photo" description="Foto da pessoa">
                                    <b-form-file @change="handlerUpload" id="photo" v-model="formPeople.photo"></b-form-file>
                                  </b-form-group>
                                  <!--                              date_of_birth-->
                                  <b-form-group class="col-sm-4" label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa">
                                    <b-form-input type="date" id="date_of_birth" v-model="formPeople.date_of_birth" required></b-form-input>
                                  </b-form-group>


                                  <div class="col-sm-4">
                                    <b-button type="submit" variant="primary" class="mt-4 btn-block">
                                      <span>Salvar</span>
                                    </b-button>
                                  </div>

                                </b-form>
                                <br>
                                <hr>
                                <br>

                                <div v-for="people in form.schools_peoples" :key="people.id">


                                  <h5>{{people.peoples.name}}</h5>
                                  <b-form @submit="savePeople($event,people.peoples)" class="was-validated row">

                                    <!--                              id_people_type-->
                                    <b-form-group class="col-sm-4" label="Tipo de pessoa" description="Selecione o tipo de pessoa">
                                      <b-form-select
                                          id="id_people_type"
                                          v-model="people.peoples.id_people_type"
                                          :options="optionsPeopleTypes"
                                          required
                                      ></b-form-select>
                                    </b-form-group>
                                    <!--                              id_people_race-->
                                    <b-form-group class="col-sm-4" label="Raça pessoa" description="Selecione raça">
                                      <b-form-select
                                          id="id_people_race"
                                          v-model="people.peoples.id_people_race"
                                          :options="optionsPeopleRaces"
                                          required
                                      ></b-form-select>
                                    </b-form-group>
                                    <!--                              sex-->
                                    <b-form-group class="col-sm-4" label="Sexo" description="Selecione o sexo">
                                      <b-form-select
                                          v-model="people.peoples.sex"
                                          :options="optionsSex"
                                          required
                                      ></b-form-select>
                                    </b-form-group>
                                    <!--                              name-->
                                    <b-form-group class="col-sm-6" label="Nome" label-for="name" description="Nome da pessoa">
                                      <b-form-input id="name" v-model="people.peoples.name" required></b-form-input>
                                    </b-form-group>
                                    <!--                              document-->
                                    <b-form-group class="col-sm-6" label="Documento" label-for="document" description="Documento da pessoa">
                                      <b-form-input id="document" v-model="people.peoples.document" required></b-form-input>
                                    </b-form-group>
                                    <!--                              photo-->
                                    <b-form-group class="col-sm-4" label="Foto" label-for="photo" description="Foto da pessoa">
                                      <b-form-file @change="handlerUpload" id="photo" v-model="people.peoples.photo"></b-form-file>
                                      <img :src="people.peoples.photo_show" alt="">
                                    </b-form-group>
                                    <!--                              date_of_birth-->
                                    <b-form-group class="col-sm-4" label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa">
                                      <b-form-input type="date" id="date_of_birth" v-model="people.peoples.date_of_birth" required></b-form-input>
                                    </b-form-group>


                                    <div class="col-sm-4">
                                      <b-button type="submit" variant="primary" class="mt-4 btn-block">
                                        <span>Salvar</span>
                                      </b-button>
                                    </div>

                                  </b-form>

                                  <button type="button" @click="removePeopleSchool(people)" class="btn btn-danger">Remover pessoa da escola</button>

                                  <br>
                                  <hr>
                                  <br>
                                </div>

                              </b-tab>
                            </b-tabs>


                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <schools-paginate :paginate="paginate" @change-page="getPaginate"></schools-paginate>
               <b-table striped hover :items="list" :fields="table" class="  table-responsive-sm table-responsive-md">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <schools-delete :value="data.item" @change="getPaginate(1)"></schools-delete>
<!--                  <schools-remove :value="data.item" @change="getPaginate(1)"></schools-remove>-->
                  <schools-show :value="data.item"></schools-show>
                </template>
              </b-table>
              <schools-paginate :paginate="paginate" @change-page="getPaginate"></schools-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

