import { render, staticRenderFns } from "./schools.vue?vue&type=template&id=062eff12&scoped=true"
import script from "./schools.vue?vue&type=script&lang=js"
export * from "./schools.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062eff12",
  null
  
)

export default component.exports